// Fonts
$base-font-stack: "Crimson Text", serif !default;
$base-font-size:   16pt !default;
$base-font-weight: 400 !default;
$base-line-height: 1.5 !default;
$header-font-stack: "FucXed","Helvetica Neue",Helvetica,Arial,sans-serif;
$small-font-size:  $base-font-size * 0.875 !default;
$smaller-font-size:  $base-font-size * 0.7 !default;

$table-text-align: left !default;

// Width of the content area
$content-width-narrow:    400px !default;
$content-width-wide:    600px !default;
$half-width:    500px !default;

$on-small:         400px !default;
$on-medium:        800px !default;
$on-large:         1300px !default;

$spacing-unit:     10px !default;
$action-bar-height: 72px !default;
$action-bar-height-mobile: 56px !default;
$rounding: 5px;


$xr-green:      #21a73d !default;
$xr-yellow:      #ffc113 !default;
$xr-light-blue:      #71d0f1 !default;
$xr-pink:      #ed9bc4 !default;
$xr-purple:      #986297 !default;
$xr-dark-blue:      #3f61b6 !default;
$xr-light-green:      #a1da68 !default;
$xr-pastel-yellow:      #f3f35b !default;

// Other colours
$black-color:       #111 !default;
$white-color:       #fdfdfd !default;
$shadow-color:     #222;
$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$text-color:       $black-color !default;



    $brand-text-color: $black-color !default;
    $brand-text-color-invert: $white-color !default;



    $accent-text-color: $black-color !default;

$text-color-light: lighten($text-color, 18%);
$background-color: $white-color !default;
$brand-color:       $xr-green !default;
$accent-color:      $xr-yellow !default;
$accent-color-dark: darken($accent-color, 15%) !default;

$on-tiny:          250px !default;
$on-small:         425px !default;
$on-medium:        850px !default;
$on-large:         1300px !default;

@media print {
    .Rebellion-Contents {
        display: none;
    }
    .breadcrumb {
        display: none;
    }
}

.Rebellion-Contents {
    background-color: $accent-color;
    color: $accent-text-color;
    line-height: 200%;
    
    a {
        color: $accent-text-color;
        font-size: $base-font-size * 1.1;
        border-bottom: 1px dashed $accent-text-color;
    }
    a:hover {
        text-decoration: none;
        border-bottom: 1px solid $accent-text-color;
    }

    ol li {
        list-style-type: decimal;
    }
    
    ol li:before {
        content: none;
    }
}

.Rebellion-Floating-Boxes {
    background-color: $background-color;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .Rebellion-Floating-Box {
        position: relative;
        background-color: $background-color;
        font-size: $small-font-size;
        box-shadow: 0 5px 10px $shadow-color;
        margin: $spacing-unit;
        padding: 2*$spacing-unit 0;
        width: 310px;

        padding-bottom: 46px + 2*$spacing-unit;;

        .at-bottom {
            position: absolute;
            bottom: 2*$spacing-unit;
            width: 100%;
        }

        .Rebellion-Button {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

        text-align: center;
        font-family: $header-font-stack;

        h2 {
            border-bottom: 4px solid $accent-color;
            padding: 0 2*$spacing-unit;
            margin: 0 $spacing-unit;
        }

        p {
            text-align: left;
            font-family: $base-font-stack;
            padding: 0 2*$spacing-unit;
        }

        &:nth-of-type(8n + 1) > h2 {
            border-bottom-color: $xr-yellow;
        }
        &:nth-of-type(8n + 2) > h2 {
            border-bottom-color: $xr-green;
        }
        &:nth-of-type(8n + 3) > h2 {
            border-bottom-color: $xr-pink;
        }
        &:nth-of-type(8n + 4) > h2 {
            border-bottom-color: $xr-dark-blue;
        }
        &:nth-of-type(8n + 5) > h2 {
            border-bottom-color: $xr-purple;
        }
        &:nth-of-type(8n + 6) > h2 {
            border-bottom-color: $xr-light-blue;
        }
        &:nth-of-type(8n + 7) > h2 {
            border-bottom-color: $xr-light-green;
        }
        &:nth-of-type(8n + 8) > h2 {
            border-bottom-color: $xr-pastel-yellow;
        }
    }
}

.Rebellion-Halves {
    display: flex;
    flex-direction: row;
    align-items: stretch; 
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-content: center;
    width: 100%;

    .half {
        flex-basis: 500px;
        flex-grow: 1; 
        padding: 56px $spacing-unit;
        box-sizing: border-box;
        background-position: center;
        background-size: cover;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .half-image {
        min-height: 300px;

        @media screen and (min-width: $on-small) {
            & { min-height: 400px;}
        }
    }

    .content-right {
        max-width: $half-width;
        float: right;
    }
    .content-left {
        max-width: $half-width;
    }
}

.image-right {
    flex-wrap: wrap-reverse;
}

.image-left {
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
}

.Rebellion-rich-text {

}

.Rebellion-Youtube-Video {
    text-align: center;

    iframe {
        width: 100%;
        max-width: 1400px;
        height: 56vw;
        max-height: 720px;
    }
}

.Rebellion-Event-List {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    & > .Rebellion-Event-Item:nth-of-type(8n + 1) > .date > .day-number {
        border-bottom-color: $xr-yellow;
    }
    & > .Rebellion-Event-Item:nth-of-type(8n + 2) > .date > .day-number {
        border-bottom-color: $xr-green;
    }
    & > .Rebellion-Event-Item:nth-of-type(8n + 3) > .date > .day-number {
        border-bottom-color: $xr-pink;
    }
    & > .Rebellion-Event-Item:nth-of-type(8n + 4) > .date > .day-number {
        border-bottom-color: $xr-dark-blue;
    }
    & > .Rebellion-Event-Item:nth-of-type(8n + 5) > .date > .day-number {
        border-bottom-color: $xr-purple;
    }
    & > .Rebellion-Event-Item:nth-of-type(8n + 6) > .date > .day-number {
        border-bottom-color: $xr-light-blue;
    }
    & > .Rebellion-Event-Item:nth-of-type(8n + 7) > .date > .day-number {
        border-bottom-color: $xr-light-green;
    }
    & > .Rebellion-Event-Item:nth-of-type(8n + 8) > .date > .day-number {
        border-bottom-color: $xr-pastel-yellow;
    }
}

.Rebellion-Event-List-Short {
    img { display: none;}
}

.Rebellion-Event-Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-color;
    box-shadow: 0 5px 10px $shadow-color;
    padding: $spacing-unit;
    padding-bottom: $spacing-unit /2;
    margin: $spacing-unit;
    max-width: $content-width-wide;
    width: 90%;

    & > img {
        margin: $spacing-unit 0 $spacing-unit/2 0;
    }

    .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .date {
            flex-basis: 60px;
            flex-grow: 0;
            flex-shrink: 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: $header-font-stack;
            padding-right: $spacing-unit;

            .day-number {
                font-size: $base-font-size * 2;
                border-bottom: 4px solid $xr-green;
                line-height: 1;
            }

            .month {
                font-size: $small-font-size; //$base-font-size * 0.8;
                color: $text-color-light;
            }
        }

        .description {
            line-height: 1;
            width: 100%;
            font-size: $small-font-size;

            h3, h4 {
                margin-top: 5px;
            }

            .bottom-line {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .Rebellion-Button {
                    margin-left: $spacing-unit;
                    position: relative;
                    bottom: $spacing-unit /2;
                }

            }
        }
    }
}

.mailing-list-signup
{
    padding-left: 5px;
    input[type=text] { padding: 9px 0;}
    input, button {
        font-size: 14pt;
    }
}

.Rebellion-Halves .half {
    padding: 10vh 10px;
}

.Rebellion-Event-List {
    padding-bottom: 50px;
}

.Rebellion-Post-Info {

    padding-bottom: 40px; 

    .author {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        img {
            flex-basis: 30%;
        }

        .text {
            flex-basis: 70%;
            padding-left: 5px;
            p {
                font-size: 12pt;
            }
        }
    }
}

.author-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;

    img {
        max-width: 20%;
        flex-basis: 20%;
        flex-grow: 1;
    }
    p {
        padding-left: 10px;
        flex-basis: 80%;
    }
}
